import React from "react";
import { Dialog } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useSelector } from "react-redux";

export default function PurchaseShareDialog() {
    const purchase = useSelector((state) => state.explorer.purchase);
    return (
        <>
            {purchase && (
                <Dialog
                    open={purchase}
                    onClose={purchase.onClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="alert-dialog-title">
                        确定要支付 {purchase.score} 积分 购买此分享？
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            购买后，您可以自由预览、下载此分享的所有内容，一定期限内不会重复扣费。如果您已购买，请忽略此提示。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={purchase.onClose}>取消</Button>
                        <Button
                            onClick={() => purchase.callback()}
                            color="primary"
                            autoFocus
                        >
                            确定
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
